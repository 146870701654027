<template>
  <div class="md:full w-full py-10 mx-auto text-left">
    <div class="mb-6">
      <h2
        class="px-3 md:px-12 lg:px-8 xl:px-10 text-left text-headerText font-medium text-lg md:text-2xl"
      >
        Edit Family Information
      </h2>
      <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>
    <form
      @submit.prevent="updateFamily"
      autocomplete="on"
      class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10"
    >
      <label class="text-dark-600 text-sm font-medium">Marital Status</label>
      <easiSelectInput2
        required
        class="mt-1 mb-5"
        @update="args.maritalStatus = $event"
        :value="args.maritalStatus"
        :options="[
          { label: 'Single', value: 'SINGLE' },
          { label: 'Married', value: 'MARRIED' },
          { label: 'Divorced', value: 'DIVORCED' },
        ]"
      />

      <h4 class="text-base font-medium mb-2 mt-1 text-secondary">
        NEXT OF KIN
      </h4>

      <!-- <label for="fullName" class="text-dark-600 text-sm font-medium"
        >Full Name</label
      > -->
      <easiTextInput
        placeholder="Full Name"
        type="text"
        name="fullName"
        class="mt-1 mb-5"
        v-model="args.nextOfKin.name"
        required
      ></easiTextInput>

      <!-- <label for="email" class="text-dark-600 text-sm font-medium"
        >Email Address:</label
      > -->
      <easiTextInput
        placeholder="Email"
        type="email"
        name="email"
        class="mt-1 mb-5"
        v-model="args.nextOfKin.email"
        :error="errorRules.email"
        autocom
      ></easiTextInput>

      <!-- <label for="phoneNumber" class="text-dark-600 text-sm font-medium"
        >Phone Numbers</label
      > -->
      <easiTextInput
        placeholder="Phone Number"
        type="number"
        name="phoneNumber"
        class="mt-1 mb-5"
        v-model="otherNumbers"
        :error="errorRules.phone"
      ></easiTextInput>

      <!-- <label for="address" class="mt-5 text-dark-600 text-sm font-medium"
        >Home Address</label
      > -->

      <easiTextArea
        class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1 mb-2"
        name="address"
        placeholder="Home Address"
        id="address"
        cols="30"
        rows="5"
        v-model="args.nextOfKin.address"
      ></easiTextArea>
      <!-- 
      <label for="relationship" class="text-dark-600 text-sm font-medium"
        >Relationship</label
      > -->
      <easiTextInput
        placeholder="Relationship"
        type="text"
        name="relationship"
        class="mt-1 mb-5"
        v-model="args.nextOfKin.relationship"
        required
      ></easiTextInput>

      <div
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="reloadPage"
          class="md:w-40 w-full cursor-pointer text-center bg-white rounded-full text-dark-600 border border-primary text-primary font-bold px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          :loading="loading"
          class="focus:outline-none text-sm py-3 px-4 bg-primary rounded-lg text-white w-80 md:w-40"
        >
          Update
        </easiButton>
      </div>
    </form>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Employee's family details updated successfully</span>
      </template>
    </easiSuccess>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";

import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";

const store = useDataStore();
const route = useRoute();
const toast = useToast();
const { validatePhone, validateEmail } = helperFunctions;

const { mutate } = store;
const updateSuccess = ref(false);

const employee = computed(() => store.getEmployeeById(route.params.id));
// onMounted(() => {

// });
const errorRules = ref({
  phone: "",
  email: "",
});
const loading = ref(false);

const otherNumbers = ref("");
let args = reactive({
  maritalStatus: "",
  nextOfKin: {
    name: "",
    relationship: "",
    address: "",
    phoneNumbers: [],
    email: "",
  },
});
if (
  employee.value &&
  employee.value.family &&
  employee.value.family.maritalStatus &&
  employee.value.family.maritalStatus.length
) {
  args = employee.value.family;
  delete args.__typename;
  delete args.nextOfKin.__typename;
  otherNumbers.value = args.nextOfKin.phoneNumbers.join(",");
  console.log(args);
}
function reloadPage() {
  store.$patch({
    pageIndex: 1,
  });
  window.location.reload();
}
async function updateFamily() {
  if (otherNumbers.value.includes(",")) {
    args.nextOfKin.phoneNumbers = otherNumbers.value.split(",");
  } else {
    let phone = [];
    phone.push(otherNumbers.value);
    args.nextOfKin.phoneNumbers = phone;
  }

  if (args.nextOfKin.phoneNumbers.length) {
    for (let num of args.nextOfKin.phoneNumbers) {
      if (!validatePhone(num)) {
        errorRules.value.phone = "Please ensure all phone numbers are valid";
        window.scrollTo({ top: 0, behavior: "smooth" });
        throw new Error("Invalid");
      }
    }
  }

  if (args.nextOfKin.email) {
    if (!validateEmail(args.nextOfKin.email)) {
      console.log(args.nextOfKin.email);
      errorRules.value.email = "Please enter a valid email address";
      window.scrollTo({ top: 0, behavior: "smooth" });
      throw new Error("Invalid");
    }
  }
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "UpdateFamily",
      data: {
        employeeId: employee.value._id,
        input: args,
      },
      service: "EMP",
    });
    console.log(res);
    if (res.maritalStatus.length) {
      store.$patch({
        pageIndex: 1,
      });
      // toast.success("Employee's family details updated successfully");
      loading.value = false;
      updateSuccess.value = true;
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  } catch (e) {
    console.log(e);
  }
}
</script>

<style></style>
